#projects {
  background-color: var(--light-purple);
}

#dark #projects {
  background-color: var(--dark-purple);
}

#project-cards-container {
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  /* max-width: 800px; */
}
