/* Header / Hero *************************************************************/
header {
  height: 100vh;
  /* height: 100%; */
  min-height: 700px;
}

/* Decorative background */
header::before {
  content: "";
  z-index: 0;
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;

  height: 100%;
  min-height: 700px;
  width: 68%;

  background-color: var(--light-green);
}

#dark header::before {
  background-color: var(--purple);
}

.header-inner {
  position: relative;
  height: 100%;

  display: grid;
  grid-template-columns: 50% 50%;
  grid-template-rows: var(--navbar-height) 0.75fr 0.75fr 0.5fr;

  justify-items: center;
  align-items: center;
}

.header-inner img {
  grid-row: 1/4;
  grid-row: 2/4;
  align-self: center;

  width: auto;
  height: 500px;
  max-height: min(100%, 30vw);
  min-height: 280px;

  border: 10px solid var(--dark-gray);
  border-radius: 3px;

  transform: rotate(-2deg);
}

#dark .header-inner img {
  border-color: var(--off-white);
}

.header-titles,
.header-text {
  justify-self: start;
}

.header-titles {
  grid-column: 2/3;
  grid-row: 2/3;

  padding-left: 10px;

  font-size: 18pt;
}

.header-text {
  grid-column: 2/3;
  grid-row: 3/4;
  align-self: baseline;

  width: min(max(40vw, 450px), 80vw);
  max-width: 50vw;
  padding-right: 15px;

  line-height: 24pt;
  font-size: 16pt;

  display: flex;
  flex-direction: column;
}

.header-text p {
  padding-bottom: 15px;
}

header .see-projects-btn {
  display: flex;
  align-items: center;
  justify-content: center;

  cursor: pointer;

  font-size: 20px;
  height: 40px;
  width: 240px;
  width: 300px;

  margin: auto;
  margin-top: 50px;
  text-align: center;
  border-radius: 100px;

  background-color: unset;
  background-color: var(--light-green);
  background-color: var(--off-white);
  background-color: var(--light-purple);
  background-color: #8b95f4;
  background-color: #af91db;

  /* background-color: lavender; */

  /* border: 2px solid var(--off-white); */
  color: var(--off-white);
  /* color: var(--dark-gray); */
}

#dark header .see-projects-btn {
  border-color: var(--light-green);
  border-color: var(--off-white);
  background-color: var(--dark-gray);
}

/* Mobile view ***************************************************************/
/* @media (max-width: 768px) { */
@media (max-width: 1100px) {
  /* Decorative background is hidden on mobile */
  header::before {
    display: none;
  }

  #dark .header-inner {
    background-color: var(--dark-gray);
  }

  .header-inner {
    padding-top: var(--navbar-height);
    padding-top: var(--navbar-mobile-height);
    display: flex;
    flex-direction: column;
  }

  .header-inner img {
    margin-top: 10px;
    margin-bottom: 20px;
    border-width: 7px;

    /* ORIGINAL */
    /* min-height: min(220px, 70vw); */
    min-height: min(200px, 70vw);
    min-height: min(155px, 70vw);
  }

  .header-titles {
    margin-bottom: 20px;
    font-size: 11pt;
  }

  .header-text {
    display: flex;
    flex-direction: column;

    max-width: unset;
    padding: unset;

    font-size: 12pt;

    width: 465px;
    max-width: 85vw;

    margin: 0px auto;
  }

  .header-text p {
    margin-bottom: 20px;
    padding: unset;
  }

  header .see-projects-btn {
    width: 240px;
    font-size: 18px;
    margin: auto;
    background-color: #af91db;
    color: var(--off-white);
  }

  #dark header .see-projects-btn {
    color: var(--off-white);
    color: var(--light-green);
    border: 2px solid var(--light-green);
  }
}

article {
  padding: 55px 0px;
  min-height: 125vh;
  min-height: 105vh;
}

article:last-of-type {
  padding: 55px 0px 0px 0px;
}

article h2 {
  font-size: xx-large;
  text-align: center;
}

/* About Me ******************************************************************/
#about {
  background-color: var(--light-green);
}

#dark #about {
  background-color: var(--purple);
}

/* Footer ********************************************************************/
footer {
  /* height: 75vh; */
  height: 50vh;
  min-height: 350px;
  min-height: 450px;
  background-color: var(--light-green);

  overflow: hidden;
  position: relative;
  text-align: center;
}

#dark footer {
  background-color: var(--purple);
}

@keyframes text-shadow {
  to {
    text-shadow: rgba(0, 0, 0, 0.5) 0px 3px 3px;
  }
}

footer ul {
  list-style: none;
  display: flex;
  justify-content: center;

  position: relative;
  top: 250px;
  z-index: 1;
}

/* Decorative circle placed in the footer; psuedo element */
footer::after {
  content: "";
  display: block;
  position: absolute;

  width: 200vw;
  height: 200vw;

  margin-left: -50vw;
  z-index: 0;
  border-radius: 50%;

  background-color: var(--off-white);
}

#dark footer::after {
  background-color: var(--dark-gray);
}
