.project-card {
  width: 100vw;
  max-width: 90vw;
  min-width: 100px;
  min-height: 87vh;

  background-color: var(--off-white);
  border-radius: 15px;

  margin: 35px 20px;
  padding-top: 18px;
  padding-bottom: 18px;

  display: flex;
  flex-direction: column;
}

#dark .project-card {
  background-color: var(--dark-gray);
}

/* Project Description *******************************************************/
.project-description {
  text-align: left;
  width: max(55%, min(85%, 350px));
  margin: auto;
  padding: 20px 0px 10px 5%;
}

.project-description ul li {
  margin: auto auto 10px auto;
}

/* Project Links *************************************************************/
.project-links-container {
  margin: auto;
  width: min(40%, 400px);
  min-width: 250px;

  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project-links-container a {
  background-color: aquamarine;

  display: flex;
  justify-content: center;
  align-items: center;

  height: 35px;

  margin: 3px 5px;
  padding: 0px 11px;
  font-size: 16pt;

  border-radius: 50px;
  width: 225px;
  width: 100%;
}

.project-links-container a i.fa,
.project-links-container a span {
  font-size: 18px;
}

.project-links-container a i.fa {
  font-size: 16pt;
}

/* View project button */
a.project-card-view-project-link {
  background-color: unset;
  border: 2px solid var(--light-green);
  color: var(--light-green);
  padding-left: 40px;
}

a.project-card-view-project-link .fa {
  color: var(--light-green);
}

#dark a.project-card-view-project-link .fa,
#dark a.project-card-view-project-link {
  color: var(--light-green-bright);
}

/* GitHub logo button */
.project-card-github-link {
  background-color: var(--dark-gray) !important;
  display: flex;
  justify-content: center;
}

#dark .project-card-github-link {
  border: 2px solid #edf0ee;
}

img.project-card-github-logo {
  height: 25px;
  width: auto;

  -webkit-filter: invert(1);
  filter: invert(1);
}

.project-card .fa.fa-brands.fa-github {
  color: #edf0ee;
  width: 0px;
  margin-left: 3px;
}

/* Carousel ******************************************************************/
/* Sets height of image slides */
.carousel-root {
  margin: 15px auto 15px auto;
  width: max(45%, min(95%, 280px));
  max-width: 60vw;
}

.carousel-slider {
  cursor: grab;
  border-radius: 10px;
  overflow: hidden;
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
  overflow: visible;
}

.carousel-slider:active:hover {
  cursor: grabbing;
}

/* Custom prev/next buttons */
.carousel-slider .carousel-arrow-left,
.carousel-slider .carousel-arrow-right {
  cursor: pointer;
  position: absolute;
  top: 86%;
  top: 83.5%;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: visible;
}

.carousel-slider .carousel-arrow-left {
  left: 5px !important;
}

.carousel-slider .carousel-arrow-right {
  right: 5px !important;
}

.carousel-arrow-left .fa,
.carousel-arrow-right .fa {
  box-shadow: rgba(0, 0, 0, 1) 0px 5px 8px;
  box-shadow: rgba(0, 0, 0, 1) 0px 0.5px 8px;
  box-shadow: rgba(0, 0, 0, 1) 0px 0px 10px;

  position: relative;
  top: 156px;

  color: var(--off-white) !important;
  z-index: 1;

  background-color: var(--dark-gray);
  border-radius: 50%;
  top: 10px;
  width: 35px;
  height: 35px;

  display: flex;
  justify-content: center;
  align-items: center;
}

/* Carousel dots */
.control-dots {
  position: absolute;
  top: 86%;
}

.carousel .control-dots li.dot {
  opacity: 0.65;
  height: 12px;
  width: 12px;
  border: 1px solid lightgray;
}

.carousel .control-dots li.dot.selected {
  opacity: 1;
  border-color: #606060;
}

/* Landscape view ************************************************************/
/* @media (min-width: 1000px) { */
@media (min-width: 700px) {
  .project-card {
    display: grid;
    grid-template-columns: 0.55fr 0.45fr;
    min-width: min(750px, 90vw);
    max-width: 45%;
    min-height: 495px;
  }

  /* Title */
  .project-card h3 {
    grid-row: 1 / 2;
    grid-column: 1 / 3;
    align-self: center;
  }

  /* Carousel */
  .carousel-root {
    width: 80%;
    width: min(35vh, max(50%, 350px));
    min-width: 275px;

    grid-column: 1 / 2;
    grid-row: 1 / 4;
    grid-row: 2 / 4;
    align-self: center;
  }

  /* Description */
  .project-description {
    grid-column: 2 / 3;
    grid-row: 2 / 3;
    align-self: center;
    justify-self: left;
    justify-self: center;
    margin: unset;
    padding: 20px 0px 10px 0px;
  }

  /* Link buttons */
  .project-links-container {
    grid-column: 2 / 3;
    grid-row: 3 / 4;
    align-self: start;
    justify-self: left;
    justify-self: center;
    margin: unset;
  }

  .project-links-container a {
    margin: 6px 5px;
    margin-top: 6px;
    margin-bottom: 6px;
  }
}
