nav {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: 3;

  height: var(--navbar-height);
  text-align: center;

  display: grid;
  grid-template-columns: 25% 50% 25%;
  grid-template-rows: 100%;

  /* Adds extra padding on mobile devices */
  padding-bottom: env(safe-area-inset-bottom);
}

/* NickLogo Container ********************************************************/
#svg-container {
  height: var(--navbar-height);

  z-index: 1000;
  position: absolute;

  grid-column: 1 / 2;
  justify-self: center;
  justify-self: right;
}

#svg-container svg {
  height: 125px;
}

/* Navbar menu ***************************************************************/
nav ul {
  grid-column: 2 / 3;
  justify-self: right;
  justify-self: center;
}

nav ul li {
  display: inline-block;
  width: 85px;
}

nav ul li a {
  line-height: var(--navbar-height);
  font-size: 14pt;
  font-weight: 600;
  display: inline-block;
  width: 100%;
}

nav ul li a span {
  cursor: pointer;
}

/* Darkmode switch ***********************************************************/
#darkmode-switch-container {
  grid-column: 3 / 4;
  align-self: center;
  justify-self: center;
  justify-self: left;
  position: relative;
  width: 60px;
  height: 34px;
}

/* Hide default HTML checkbox */
#darkmode-switch-container input {
  opacity: 0;
  width: 0;
  height: 0;
}

.darkmode-slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  background-color: var(--dark-gray);
  background-color: gray;
  background-color: var(--dark-purple);
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

.darkmode-slider:before {
  position: absolute;
  content: "";
  height: 26px;
  width: 26px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  background-color: #f4e08a;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}

input:checked + .darkmode-slider {
  background-color: #2196f3;
  background-color: var(--light-green);
  background-color: #83c8e5;
}

input:focus + .darkmode-slider {
  box-shadow: 0 0 1px #2196f3;
}

input:checked + .darkmode-slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}

/* Rounded sliders */
.darkmode-slider.round {
  border-radius: 34px;
}

.darkmode-slider.round:before {
  border-radius: 50%;
}

/* Mobile view ***************************************************************/
/* @media (max-width: 768px) { */
@media (max-width: 900px) {
  nav {
    height: var(--navbar-mobile-height);
    grid-template-columns: 40% 20% 40%;
    grid-template-columns: 1fr 1fr 1fr;
  }

  nav ul li a {
    /* line-height: calc(var(--navbar-height) / 2); */
    line-height: calc(var(--navbar-mobile-height) / 2);
  }

  #svg-container {
    height: var(--navbar-mobile-height);
    justify-self: center;
    padding-left: 40px;
  }

  #svg-container svg {
    height: 110px;
  }

  #navbar-menu {
    background-color: var(--off-white);
    position: fixed;
    bottom: 0;
    width: 100%;
    z-index: 1000;
  }

  #dark #navbar-menu {
    background-color: var(--dark-gray);
  }

  #navbar-menu li a {
    font-size: 12pt;
  }

  #darkmode-switch-container {
    justify-self: center;
  }

  #scroll-progress-bar-container {
    background-color: var(--dark-gray);
    width: 100%;
  }

  #scroll-progress-bar {
    /* background-color: orange; */
    background-color: var(--logo-color);
    display: visible;
    height: 2px;
    width: 0%;
  }

  #dark #scroll-progress-bar-container {
    background-color: var(--off-white);
  }

  /* Disable scrollbar */
  /* body::-webkit-scrollbar {
    width: 0px;
  } */
}
