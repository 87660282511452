#about {
  min-height: unset;
  padding-bottom: 30px;
}

#about h2 {
  margin-bottom: 30px;
}

#about section {
  display: grid;
  grid-template-columns: 0.5fr 0.5fr;
  align-items: center;
}

#about img {
  justify-self: right;
  margin-right: 50px;

  max-width: 80vw;
  width: max(300px, 20vw);

  height: auto;
  border: 10px solid var(--dark-gray);
  border-radius: 3px;
}

#about section div {
  width: min(450px, 85%);
}

#about section div p {
  font-size: 13pt;
  margin-bottom: 15px;
  line-height: 20pt;
}

#about section div p strong {
  color: var(--dark-gray);
}

#dark #about section div p strong {
  color: var(--off-white);
}

/* Nick Logo */
#about #svg-container svg {
  height: 100px;
  position: relative;
}

@media (max-width: 870px) {
  #about section {
    flex-direction: column;
    display: flex;
  }

  #about section img {
    margin-bottom: 30px;
    margin-right: unset;
  }

  #about section div {
    /* margin-left: 15px; */
  }
}
