#resume {
  background-color: #f1f1f1;
  overflow-x: hidden; /* Prevents AOS fade-left elements from generating horizontal scroll */
}

#dark #resume {
  background-color: var(--dark-gray);
}

#resume strong {
  color: var(--dark-gray);
}

#dark #resume strong {
  color: var(--off-white);
}

#resume section {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-bottom: 30px;
}

#resume h3 {
  margin-bottom: 40px;
}

.experience,
.education {
  width: min(max(40vw, 450px), 83vw);
  /* margin: 20px 0px; */
  /* margin-bottom: 30px; */
}

.experience,
.education div {
  border-left: 3px solid gray;
  padding-left: 15px;
  margin-bottom: 15px;
}

#resume li {
  margin-top: 5px;
  margin-left: 20px;
}

.education div {
  margin-bottom: 20px;
}

.coursework {
  display: flex;
  flex-wrap: wrap;
}

.coursework li {
}
