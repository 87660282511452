.scroll-btn {
  position: relative;
  top: 150px;
  z-index: 1;
  border: none;
  background: none;
  cursor: pointer;
  /* margin: 0; */
  /* padding: 0; */
}

.scroll-btn i {
  font-size: 35pt;
  line-height: 0;
}

.scroll-btn i:hover {
  animation: text-shadow forwards 0.25s;
}
