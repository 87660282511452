:root {
  --light-gray: ;
  --dark-gray: #303030;
  --off-white: #fefefa;

  --light-green: #6fdcbf;
  --light-green-bright: #83ffde;

  --light-blue: skyblue;

  --purple: #774069;
  --light-purple: #bca7dc;
  --dark-purple: #4c3a69;
  --dark-purple-text: #7a45cf;

  --logo-color: orange;

  --navbar-height: 120px;
  --navbar-mobile-height: 100px;

  --main-font-family: "Rubik", sans-serif;
}

*,
*:before,
*:after {
  padding: 0px;
  margin: 0px;
  box-sizing: border-box;
  font-family: var(--main-font-family);
}

.margin-auto {
  margin: auto;
}

a:link,
a:visited,
a:hover,
a:active {
  text-decoration: none;
  color: var(--dark-gray);
}

a.underline-on-hover:hover {
  text-decoration: underline;
  text-decoration-thickness: 2px;
}

.light,
html {
  color: var(--dark-gray);
  background-color: var(--off-white);
}

#dark {
  color: var(--off-white);
  background-color: var(--dark-gray);
}

#dark a {
  color: var(--off-white);
}

.box-shadow {
  box-shadow: rgba(0, 0, 0, 0.5) 0px 4px 12px;
}

.no-highlight {
  -khtml-user-select: none;
  -o-user-select: none;
  -moz-user-select: none;
  -webkit-user-select: none;
  user-select: none;
}

/* Font Awesome */
/* Applied to all Font Awesome 5 Free elements */
.fa,
.fa:before,
.fa:after {
  font-family: "Font Awesome 5 Free";
}

/* Applied to all Font Awesome 5 Brands elements */
.fa-brands,
.fa-brands:before,
.fa-brands:after {
  font-family: "Font Awesome 5 Brands";
}

.fa {
  color: var(--dark-gray);
  font-size: 30pt;
  margin: auto 10px;
}

#dark .fa {
  color: var(--off-white);
}

/* Scrollbar */
body::-webkit-scrollbar {
  width: 11px;
}

body::-webkit-scrollbar-track {
  background-color: var(--off-white);
}

body::-webkit-scrollbar-thumb {
  background: var(--dark-gray);
  border-radius: 10px;
}

#dark body::-webkit-scrollbar-track {
  background-color: var(--dark-gray);
}

#dark body::-webkit-scrollbar-thumb {
  background: var(--logo-color);
  /* background: var(--off-white); */
}

strong {
  color: var(--dark-purple-text);
}

#dark strong {
  color: var(--light-green-bright);
}
